import { Route, Routes } from "react-router-dom";
import LayoutMain from "../layouts/layoutMain";
import { HomePage } from "../pages/HomePage";
import { QrRedirectionPage } from "../pages/QrRedirectionPage";

export function RouteIndex() {
  return (
    <>
      <Routes>
        <Route element={<LayoutMain />}>
          <Route index element={<HomePage />} />
          <Route path="q/:qrSlug" element={<QrRedirectionPage />} />
        </Route>
      </Routes>
    </>
  );
}
