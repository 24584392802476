import { Flex, Heading, Stack } from "@chakra-ui/react";

export function HomePage() {
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      py={12}
      bg={"gray.50"}
    >
      <Stack
        boxShadow={"2xl"}
        bg={"white"}
        rounded={"xl"}
        p={10}
        spacing={8}
        align={"center"}
      >
        <Stack align={"center"} spacing={2}>
          <Heading
            fontSize={"4xl"}
            bgGradient="linear(to-l, #7928CA, #FF0080)"
            bgClip="text"
          >
            JP4.in
          </Heading>
        </Stack>
      </Stack>
    </Flex>
  );
}
