import { Box, Flex, Stack, keyframes } from "@chakra-ui/react";
import { useState } from "react";
import { TbQrcode, TbQrcodeOff } from "react-icons/tb";
import { useParams } from "react-router-dom";
import { getQrCheck } from "../services/qrService";
import Bowser from "bowser";

const pulse = keyframes({
  "100%": {
    backgroundPosition: "left",
  },
});

export function QrRedirectionPage() {
  const [message, setMessage] = useState<String>("QR Loading...");
  const [loading, setLoading] = useState<Boolean>(false);
  const [urlLink, setUrlLink] = useState<String | null>();
  const qrId = useParams()?.qrSlug;

  const browser = Bowser.getParser(window.navigator.userAgent);
  console.log(browser);

  getQrCheck({ qr_code: qrId, user_agent: browser })
    .then((response) => {
      if (response?.status === 200 && response.data) {
        setLoading(true);
        setMessage(
          response.data.status === true ? "QR found!" : "QR Not found!"
        );
        if (response.data.status === true) {
          setUrlLink(response.data.url);
          window.location.replace(response.data.url);
        }
      }
    })
    .catch((error) => {
      // console.log(error);
      setMessage("QR Not found!");
    });

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      py={12}
      bg={"gray.50"}
    >
      <Stack
        boxShadow={"2xl"}
        bg={"white"}
        rounded={"xl"}
        p={10}
        spacing={8}
        align={"center"}
      >
        <Stack align={"center"} spacing={2}>
          {loading === false ? (
            <>
              <TbQrcode size={"100px"} />
              <Box
                fontSize={"3xl"}
                width={"fit-content"}
                fontWeight="bold"
                background="linear-gradient(90deg, #000 50%, #0000 0) right/200% 100%"
                animation={`${pulse} 5s infinite linear`}
                _before={{
                  content: `'${message}'`,
                  color: "#0000",
                  padding: "0 9px",
                  background: "inherit",
                  backgroundImage: "linear-gradient(90deg, #fff 50%, #000 0)",
                  webkitBackgroundClip: "text",
                  backgroundClip: "text",
                }}
              ></Box>
            </>
          ) : (
            <>
              {urlLink ? (
                <TbQrcode size={"100px"} />
              ) : (
                <TbQrcodeOff size={"100px"} />
              )}
              <Box
                fontSize={"3xl"}
                width={"fit-content"}
                fontWeight="bold"
                bgGradient="linear(to-l, #7928CA, #FF0080)"
                bgClip="text"
              >
                {message}
              </Box>
              {urlLink ?? ""}
            </>
          )}
        </Stack>
      </Stack>
    </Flex>
  );
}
