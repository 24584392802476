import axios from "axios";
import JwtToken from "../_lib/jwtToken";

let objHeader = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Bearer: null,
};

let AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + "/",
  headers: objHeader,
});

AxiosInstance.interceptors.request.use(async function (config) {
  //   const tokenName = process.env.REACT_APP_LOCAL_TOKEN;
  //   const tokenLocal = JSON.parse(localStorage.getItem(tokenName));
  //   if (tokenLocal != null) {
  //     const authToken = decryptData(tokenLocal);
  //     config.headers["Bearer"] = authToken.token;
  //   }
  const token = await JwtToken();
  console.log(token);
  config.headers["X-MY-SHOP-HMAC-SHA256"] = token;
  return config;
});

export default AxiosInstance;
